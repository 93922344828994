// prefer default export if available
const preferDefault = m => m && m.default || m

exports.components = {
  "component---src-templates-park-tsx": () => import("/vercel/path0/src/templates/park.tsx" /* webpackChunkName: "component---src-templates-park-tsx" */),
  "component---src-pages-engagement-tsx": () => import("/vercel/path0/src/pages/engagement.tsx" /* webpackChunkName: "component---src-pages-engagement-tsx" */),
  "component---src-pages-index-tsx": () => import("/vercel/path0/src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */),
  "component---src-pages-parks-tsx": () => import("/vercel/path0/src/pages/parks.tsx" /* webpackChunkName: "component---src-pages-parks-tsx" */),
  "component---src-pages-previous-tsx": () => import("/vercel/path0/src/pages/previous.tsx" /* webpackChunkName: "component---src-pages-previous-tsx" */),
  "component---src-pages-princips-tsx": () => import("/vercel/path0/src/pages/princips.tsx" /* webpackChunkName: "component---src-pages-princips-tsx" */)
}

